/* _ezentrum_customize_productdetail.scss for custom styling on all productdetail pages */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_productdetail {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					@include ezentrum_mixins.flex-flow(column,wrap);
					background-color:white;
					padding:ezentrum_variables.$global-padding;
					.ez_productdetail_top,
					.ez_productdetail_bottom {
						display:block;
						margin-bottom: ezentrum_variables.$global-margin;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(space-between);
							@include ezentrum_mixins.flex-flow(row,nowrap);
						}
					}
					.ez_productdetail_top {
						padding-top: ezentrum_variables.$global-padding;
						max-width:100%;
						&:after {
							content:"";
							display:block;
							clear:both;
						}
						.ez_productimage_wrapper {
							padding:0;
							width:100%;
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								padding-right:ezentrum_variables.$global-padding;
								width:47%;
							}
							.ez_productimage {
								position:relative;
								position: sticky;
								display:grid;
								top: 180px;
								margin-top: ezentrum_variables.$global-margin;
								img {
									max-height:450px;
									width:unset;
								}
								.siegel_wrapper {
									position:absolute;
									right:40px;
									width:60px;
									height:auto;
									text-align:center;
									font-size:0.7rem;
									line-height:0.7rem;
									z-index:21;
									img {
										width:70px;
									}
									p {
										margin:0;
									}
								}
								.rabatt_wrapper {
									position:absolute;
									@include ezentrum_mixins.display-flex;
									@include ezentrum_mixins.flex-justify-content(flex-start);
									@include ezentrum_mixins.flex-flow(column,nowrap);
									top:30%;
									left:-1 * ezentrum_variables.$global-margin;
									padding:0.25 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
									color:ezentrum_variables.$white;
									background-color:ezentrum_custom_variables.$red;
									font-weight:700;
									z-index:20;
								}
								@include ezentrum_mixins.for-size(tablet-portrait-up) {
									padding-right: 4 * ezentrum_variables.$global-padding;
									min-height:650px;
									img {
										max-height:650px;
									}
									.siegel_wrapper {
										top:100px;
										width:80px;
										.siegel_element {
											height:90px;
										}
									}
								}
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									.siegel_wrapper {
										right:100px;
									}
								}
								&.ez-swiperext {
									top: unset;
									.swiper{
										width: 100%;
										opacity: 0;
										transition: all 0.2s ease-in-out;
										&.swiper-initialized {
											padding-bottom: 2 * ezentrum_variables.$global-padding;
											opacity: 1;
											.swiper-pagination {
												bottom:0;
											}
										}
									}
									.ez_productimage_thumbs{
										padding: ezentrum_variables.$global-padding;
										width:100%;
										height: 150px;
										overflow:hidden;
										.swiper {
											.swiper-wrapper {
												text-align:center;
											}
										}
									}
									.swiper-button-next:after,
									.swiper-button-prev:after {
										color: #58585a;
									}
									.ez_swiper_next_btn, .ez_swiper_prev_btn {
										position: absolute;
										top: 50%;
										width: calc(var(--swiper-navigation-size)/ 44 * 27);
										height: var(--swiper-navigation-size);
										margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
										z-index: 10;
										cursor: pointer;
										display: flex;
										align-items: center;
										justify-content: center;
										color: var(--swiper-navigation-color,var(--swiper-theme-color));
										right: auto;
											&:after {
											color: ezentrum_variables.$primary-color;
											font-family: swiper-icons;
											font-size: 1.6rem;
											text-transform: none!important;
											letter-spacing: 0;
											font-variant: initial;
											line-height: 1;
										}
									}
									.ez_swiper_prev_btn { left: 10px; }
									.ez_swiper_next_btn { right: 10px }
									.ez_swiper_prev_btn:after { content: 'prev'; }
									.ez_swiper_next_btn:after { content: 'next'; }
									.swiper-pagination-bullet-active {
										// background:ezentrum_variables.$tertiar-color !important;
									}
								}
								
	
							}
						}
						.ez_productdescription_wrapper {
							display: block;
							padding: ezentrum_variables.$global-padding 0 0 0;
							width:100%;
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								padding: ezentrum_variables.$global-padding 0 0 ezentrum_variables.$global-padding;
								width:53%;
							}
							.ez_productheadline {
								h1 {
									margin-bottom: 2 * ezentrum_variables.$global-margin;
									font-size:1.2rem;
									font-weight:700;
									text-transform:unset;
									.erzeuger {
										font-size:0.8rem;
										font-weight:400;
									}
								}
							}
							.ez_productdescription {
								margin-top:80px;
								padding-right: ezentrum_variables.$global-padding;
								.bezeichnung_401 {
									margin-bottom: 2 * ezentrum_variables.$global-margin;
									font-weight:bold;
									color: ezentrum_custom_variables.$red;
								}
								p.ez-module-articletext strong,
								p.ez-module-articletext.red-text,
								p.ez_articletext strong,
								p.ez_articletext.red-text {
									color: ezentrum_custom_variables.$red;
								}
							}
						}
					.ez_productdetail_bottom {}
					}
					.ez_productinfos_wrapper {
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(space-between);
						@include ezentrum_mixins.flex-flow(column,wrap);
						width:100%;
						.ez_product {
							width:100%;
							font-size:0.85 * ezentrum_variables.$body-font-size;
							@include ezentrum_mixins.for-size(tablet-landscape-up) {
								width:75%;
							}
							.lieferkommentar {
								display:block;
								font-weight:400;
								color:ezentrum_custom_variables.$red;
								a {
									color:ezentrum_custom_variables.$red;
									text-decoration:none;
								}
							}
							.ez_artnr {
								font-size:ezentrum_variables.$body-font-size;
							}
						}
						.ez_product_legalinformation {
							font-size: 0.85 * ezentrum_variables.$body-font-size;
							font-weight:400;
							.ez_availability {
								&.avail_reservierbar .ez_availabilityicon {
									background-color:#f08b1e;
								}
								&.avail_subskription .ez_availabilityicon {
									background-color:#294b64;
								}
							}
						}
						.ez_priceinfos {
							margin-top:0;
							margin-bottom:0;
							padding-top:0;
							padding-bottom:0.5 * ezentrum_variables.$global-padding;
							padding-left:ezentrum_variables.$global-padding;
							font-size: 0.85 * ezentrum_variables.$body-font-size;
							font-weight:400;
							.ez_productprice {
								.ez_normalpreis,
								.ez_sonderpreis {
									font-size: 1.2rem;
									font-weight:700;
								}
								.ez_sonderpreis {
									color: ezentrum_custom_variables.$red;
								}
								.ez_grundpreis {
									color: ezentrum_variables.$body-font-color;
									font-size: 0.85 * ezentrum_variables.$body-font-size;
									font-weight:700;
								}
								.ez_addition {}
								.preisinfos {
									.pi_ust {
										&:after {
											content:"\a";
											white-space:pre;
										}
									}
									.pi_versand {}
								}
							}
						}
						.ez_productinfos_top,
						.ez_priceinfos_2 {
							letter-spacing:0;
						}
						.ez_priceinfos_2 {
							margin-top:0;
							margin-bottom:0;
							padding-top:0;
							padding-bottom:0.5 * ezentrum_variables.$global-padding;
							padding-left:0;
							font-size: 0.85 * ezentrum_variables.$body-font-size;
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								margin-left:-1 * ezentrum_variables.$global-margin;
								margin-right:-1 * ezentrum_variables.$global-margin;
								padding-left:0;
							}
						}
						.to_cart_wrapper {
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								padding: 0 ezentrum_variables.$global-padding;
								max-width:340px;
							}
						}
						.ez_productinfos_left {
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								max-width:340px;
							}
							table.staffelpreise {
								margin-bottom:ezentrum_variables.$global-margin;
								width:100%;
								th, td {
									border-bottom:1px solid ezentrum_variables.$gray;
								}
								th {
									font-weight:400;
								}
							}
							.formate {
								position:relative;
								a {
									text-decoration:none;
								}
								.formate-titel {
									position:relative;
									margin: ezentrum_variables.$global-margin 0;
									padding: 0.5 * ezentrum_variables.$global-padding;
									border:1px solid ezentrum_variables.$primary-color;
									font-weight:400;
									.ez_icon {
										position: absolute;
										top: .4rem;
										right: .6rem;
										margin: 0;
										&.close {
											display:none;
										}
									}
								}
								.formate-content {
									display:none;
									position:absolute;
									padding:0;
									top:3rem;
									left:0;
									width:100%;
									background-color: ezentrum_variables.$white;
									border:1px solid ezentrum_variables.$gray;
									.formate-item {
										@include ezentrum_mixins.display-flex;
										@include ezentrum_mixins.flex-justify-content(space-around);
										@include ezentrum_mixins.flex-flow(row,wrap);
										padding: 0.5 * ezentrum_variables.$global-padding;
										border-bottom:1px solid ezentrum_variables.$gray;
										.formate-image {
											flex:40px 0 0;
											img {
												width:20px;
												max-width:100%;
											}
										}
										.formate-name {
											flex:calc(100% - 40px) 0 0;
											.formate-productnumber {}
											.formate-jahrgang {}
											.formate-price {
												font-weight:400;
											}
										}
									}
								}
								input[type="checkbox"]:checked ~ .formate-content {
									display:block;
								}
								input[type="checkbox"]:checked ~ .formate-titel .ez_icon.open {
									display:none;
								}
								input[type="checkbox"]:checked ~ .formate-titel .ez_icon.close {
									display:block;
								}
							}
						}
					}
					.ez_teasertext {
						width:100%;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							padding-left: ezentrum_variables.$global-padding;
							padding-right: 2 * ezentrum_variables.$global-padding;
							width:47%;
						}
					}
					.weindetails {
						position:relative;
						width:100%;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							width:53%;
							padding-left: ezentrum_variables.$global-padding;
						}
						.weininfo-left,
						.weininfo-right {
							a {
								color: ezentrum_variables.$body-font-color;
								&:hover {
									color: ezentrum_custom_variables.$red;
								}
							}
						}
						.langtext_201 {
							margin-top:ezentrum_variables.$global-margin;
								font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
							color:ezentrum_variables.$gray;
						}
					}
					.ez_alternatives_wrapper {
						.ez_alternatives {
							.ez_alternatives_item {
								background-color:rgb(237,237,237);
								.ez_product {
									.ez_productimage {
										background-color:#fff;
									}
									.ez_productname {
										margin-top:ezentrum_variables.$global-margin;
										h2 {
											color:ezentrum_variables.$body-font-color;
										}
									}
									.ez_productnumber {
										font-size:0.7 * ezentrum_variables.$body-font-size;
										line-height:0.7 * ezentrum_variables.$body-line-height;
									}
									.ez_availability {
										position: relative;
										top: unset;
										right: unset;
										float:left;
									}
								}
								.ez_producttext {
									display:none;
									position:absolute;
									top:0;
									left:0;
									padding:ezentrum_variables.$global-padding;
									width:100%;
									height:100%;
									background-color:rgba(226,3,0,0.6);
									color:ezentrum_variables.$white;
									text-decoration:none;
									span {
										display:block;
										margin:0;
										padding:0;
										width:100%;
										height:100%;
										overflow:hidden;
									}
								}
								&:hover .ez_producttext {
									display:block;
								}
							}
						}
					}
				}

			}
		}
	}
}

.lightbox-container {
	width: 100% !important;
	height: 100% !important;
	.inner {
		height: calc(100% - 100px) !important;
		.lightbox-buttons {
			.previous,
			.next,
			.close {
				margin: 0px;
				padding: 0px;
				border: none;
				background-color: transparent;
				color: #000;
				line-height: ezentrum_variables.$body-line-height;
				i {
					padding: ezentrum_variables.$global-padding;
					font-size:2.5 * ezentrum_variables.$body-font-size;
					background-color: transparent;
					color: ezentrum_variables.$gray;
					transition: transform 0.2s ease-in-out;
				}
			}
			.close {
				position: absolute;
				top: ezentrum_variables.$global-margin;
				right: ezentrum_variables.$global-margin;
				width: 40px;
				height: 40px;
				opacity: .55;
				color: #000;
				font-size: 30px !important;
				&:hover {
					opacity: 1;
					cursor: pointer;
				}
			}
		}
		.item.image {
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				width: auto;
				height: inherit;
				max-height: 100vw;
				padding: 25px 0;
			}
		}
	}
}
[data-ez-overlay][data-ez-open=true] {
	cursor: default;
	position: fixed;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.6);
	z-index: 1000000;
}
