﻿@use "../ezentrum_variables";

.tooltip_container {
	position: relative;
	.tooltip {
		position: absolute;
		display: none;
		top: 100%;
		border: none;
		border-radius: ezentrum_variables.$global-radius;
		background-color: ezentrum_variables.$white;
	}
	&.right .tooltip {right: 0%; }
	&.left .tooltip {left: 0%; }
	&.bottom .tooltip {top: calc( 100% + 10px ); }
	&.top .tooltip {top: calc( -100% - 10px ); }
	&:hover .tooltip {display: block;}
}
