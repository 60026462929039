/* _ezentrum_customize_productlist.scss for custom styling on all productlist pages */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_productlist_functions {
					@include ezentrum_mixins.display-flex;
					@include ezentrum_mixins.flex-justify-content(space-between);
					@include ezentrum_mixins.flex-flow(row,wrap);
					@include ezentrum_mixins.for-size(tablet-landscape-up) {
						flex-wrap:nowrap;
					}
					.ez_productfilters {
						flex: 1 0 66%;
						@include ezentrum_mixins.display-flex;
						@include ezentrum_mixins.flex-justify-content(flex-start);
						@include ezentrum_mixins.flex-flow(row,wrap);
						#main_filter,
						#additional_filter {
							.ez_productfilters_dropdown {
								position:unset;
								@include ezentrum_mixins.for-size(tablet-portrait-up) {
									position:relative;
								}
								display:inline-block;
								margin-right:ezentrum_variables.$global-margin;
								float:left;
								> a.label,
								> label {
									padding: 0.5 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
									height: 2.1rem;
									line-height:1.2rem;
									border:1px solid ezentrum_variables.$primary-color;
									cursor: pointer;
									text-decoration:none;
								}
								> a.label.active,
								&:hover > a.label,
								&:hover > label.open {
									background-color:ezentrum_custom_variables.$light-yellow;
									color: ezentrum_variables.$body-font-color;
								}
								> input[type="radio"],
								.ez_productfilters_items,
								label.close {
									display:none;
								}
								> input[type="radio"]:checked ~ .ez_productfilters_items {
									display:block;
								}
								> input[type="radio"]:checked ~ label.close {
									position: fixed;
									display:block;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									border:none;
									background-color:transparent;
									z-index: 98;
								}
								.ez_productfilters_items {
									position:absolute;
									left:0;
									top:5rem;
									@include ezentrum_mixins.for-size(tablet-portrait-up) {
										top:2.2rem;
									}
									padding-top:0.5 * ezentrum_variables.$global-padding;
									padding-right:0.5 * ezentrum_variables.$global-padding;
									min-width:250px;
									width: fit-content;
									max-height:500px;
									background-color: white;
									box-shadow:0 0 10px 0 ezentrum_variables.$gray;
									border-radius: ezentrum_variables.$global-radius;
									background-color: ezentrum_variables.$white;
									overflow-y:scroll;
									z-index:99;
									scrollbar-color: ezentrum_variables.$gray;
									scrollbar-width: 0.5em;
									&::-webkit-scrollbar {
										width: 0.5em;
									}
									&::-webkit-scrollbar-track {
										box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
									}
									&::-webkit-scrollbar-thumb {
										background-color: darkgrey;
										outline: 1px solid ezentrum_variables.$gray;
									}
									&#toggle-Alkohol,
									&#toggle-Preis {
										padding-right:0;
										overflow-y:hidden;
									}
									.ez_productfilters_item {
										padding:0.5 * ezentrum_variables.$global-padding;
										padding-top:0;
										label {
											position:relative;
											margin:0;
											width:100%;
											cursor: pointer;
											input[type="checkbox"] {
												float:left;
												visibility:hidden;
												margin-top: 4px;
												margin-right:0.5 * ezentrum_variables.$global-margin;
											}
											.checkmark {
												position:absolute;
												top:6px;
												left:1px;
												width:12px;
												height:12px;
												border:1px solid ezentrum_variables.$primary-color;
												background-color:ezentrum_variables.$white;
												background-size:cover;
											}
											input[type="checkbox"]:checked ~ .checkmark {
												border:1px solid ezentrum_variables.$gray;
												background-color:ezentrum_custom_variables.$light-yellow;
												background-image:url(/resources/libraries/bootstrap-icons/check.svg);
											}
											span {
												display:inline-block;
												margin:0;
												padding:0;
												width:30px;
												float:right;
												text-align:right;
											}
											&.ez_filter_disabled {
												color: ezentrum_variables.$gray;
												pointer-events: none;
												.checkmark {
													border:1px solid ezentrum_variables.$gray;
												}
											}
										}
									}
								}
								.ez_slider_headline {
									margin:0 0 ezentrum_variables.$global-margin 0;
									padding:0 ezentrum_variables.$global-padding;
									text-align:center;
								}
								.ez_slider_container {
									margin: ezentrum_variables.$global-margin;
									margin-right:2 * ezentrum_variables.$global-margin;
									height:2px;
									&.noUi-target {
										margin-bottom:2 * ezentrum_variables.$global-margin;
										border:none;
										border-bottom:2px solid ezentrum_variables.$primary-color;
									}
									.noUi-connects {
										margin:0;
										.noUi-connect {
											background-color:ezentrum_variables.$white;
										}
										&:hover {
											cursor: pointer;
										}
									}
									.noUi-origin {
										top:-3px;
										.noUi-handle {
											width:20px;
											height:20px;
											border:none 0;
											border-radius:50%;
											background-color:ezentrum_variables.$body-font-color;
											cursor: pointer;
											&:before,
											&:after {
												display:none;
											}
										}
									}
								}
								.ez_number_input_area {
									display: flex;
									margin-bottom: 0.5 * ezentrum_variables.$global-margin;
									justify-content: space-evenly;
									input {
										margin:0;
										padding:0;
										width: 69px;
										min-height:0.5 * ezentrum_variables.$body-line-height;
										text-align:center;
										border:none;
										border-bottom:1px solid ezentrum_variables.$gray;
									}
								}
								.ez_slider_confirm {
									margin:0;
									padding:ezentrum_variables.$global-padding;
									width:100%;
									text-align:right;
									color:ezentrum_variables.$primary-color;
									background-color:ezentrum_variables.$white;
									&:hover {
										color:ezentrum_variables.$body-font-color;
									}
								}
							}
						}
						#main_filter {
							float:left;
							position:relative;
							margin-right:-1 * ezentrum_variables.$global-margin;
						}
						> label {
							display:none;
							padding-top:5px;
							width:unset;
							> svg {
								margin-top: -5px;
								margin-right: 0.5 * ezentrum_variables.$global-margin;
								background-image:url(/resources/images/pinard/icon-filter.svg);
							}
							&:hover {
								color:ezentrum_variables.$primary-color;
								cursor:pointer;
								> svg {
									background-image:url(/resources/images/pinard/icon-filter-yellow.svg);
								}
							}
						}
						#additional_filter_toggle,
						#additional_filter {
							display:none;
						}
						input[type="checkbox"]#additional_filter_toggle:checked ~ #additional_filter {
							display:block;
							clear:both;
						}
						#additional_filter {
							align-self:flex-end;
							width:100%;
							position:relative;
							@include ezentrum_mixins.for-size(tablet-portrait-up) {
								position:unset;
							}
						}
					}
					.ez_productlist_sort {
						flex: 1 0 33%;
						width:unset;
						min-width:280px;
						.ez_form_item {
							margin-left: 0;
							@include ezentrum_mixins.for-size(tablet-landscape-up) {
								margin-left:2 * ezentrum_variables.$global-margin;
							}
						}
						label {
							flex:1 0 30%;
							padding-top: 0.5 * ezentrum_variables.$global-padding;
						}
						.sort_page {
							flex:1 0 70%;
						}
					}
				}
				.ez_productfilters_selected {
					display: flex;
					flex-wrap: wrap;
					gap: ezentrum_variables.$global-margin;;
					.ez_activeFilter {
						display: flex;
						margin: 0;
						padding: 0.5 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
						width: fit-content;
						height: 2.1rem;
						line-height:1.2rem;
						border:1px solid ezentrum_variables.$gray;
						align-items: center;
						cursor: pointer;
					}
				}
				.ez_productlist {
					display: grid;
					grid-template-columns: repeat(auto-fill, minmax(380px, auto));
					flex-flow:unset;
					justify-content:unset;
					margin-top: ezentrum_variables.$global-margin;
					width:calc(100% + (2 * #{ezentrum_variables.$global-margin}));
					.ez_product_wrapper {
						flex: unset;
						padding-top: 2 * ezentrum_variables.$global-padding;
						width:unset;
						background-color:white;
						border:none;
						.ez_product {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(space-between);
							@include ezentrum_mixins.flex-flow(row,nowrap);
							margin-bottom: -1 * ezentrum_variables.$global-margin;
							.ez_productimage {
								height:280px;
							}
							.ez_product_left,
							.ez_product_right {
								font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
								line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
							}
							.ez_product_left {
								position:relative;
								flex:1 0 120px;
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									flex:1 0 120px;
								}
								a {
									display:block;
									margin:0;
									padding:0;
								}
								.ez_productimage {
									position:relative;
									margin:0;
									padding:0;
									width:100%;
									height:280px;
									picture,
									img {
										width:unset;
										max-height:280px;
									}
								}
								.siegel_wrapper {
									position:absolute;
									@include ezentrum_mixins.display-flex;
									@include ezentrum_mixins.flex-justify-content(flex-start);
									@include ezentrum_mixins.flex-flow(column,nowrap);
									top:30px;
									right:10px;
									height:65px;
									pointer-events: none;
									.siegel_element {
										min-width:42px;
										max-height:80px;
										text-align:center;
										img {
											max-height:36px;
										}
										p.zusatzinfo {
											margin:0;
											font-size: calc(0.5 * #{ezentrum_variables.$body-font-size});
										}
									}
								}
								.rabatt_wrapper {
									position:absolute;
									@include ezentrum_mixins.display-flex;
									@include ezentrum_mixins.flex-justify-content(flex-start);
									@include ezentrum_mixins.flex-flow(column,nowrap);
									top:-3 * ezentrum_variables.$global-margin;
									left:-1 * ezentrum_variables.$global-margin;
									padding:0.25 * ezentrum_variables.$global-padding ezentrum_variables.$global-padding;
									color:ezentrum_variables.$white;
									background-color:ezentrum_custom_variables.$red;
									font-weight:700;
								}
							}
							.ez_product_right {
								flex:2 0 160px;
								@include ezentrum_mixins.for-size(tablet-landscape-up) {
									flex:2 0 160px;
								}
								.ez_productname {
									height: 6rem;
									h4 {
										margin:0;
										padding:0;
										font-size: ezentrum_variables.$body-font-size;
										font-weight:700;
										color:ezentrum_variables.$body-font-color;
										hyphens:none;
										overflow:hidden;
										a {
											display:block;
											width:100%;
											height:100%;
											text-decoration:none;
											.erzeuger {
												font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
												line-height:calc(0.85 * #{ezentrum_variables.$body-line-height});
												font-weight: 400;
											}
										}
									}
								}
								.teasertext {
									@include ezentrum_mixins.display-flex;
									@include ezentrum_mixins.flex-justify-content(flex-start);
									@include ezentrum_mixins.flex-flow(row,nowrap);
									margin-left:-3px;
									padding:0.5 * ezentrum_variables.$global-padding 0;
									height:76px;
									line-height:1rem;
									font-weight:400;
									overflow:hidden;
									a {
										display:block;
										width:100%;
										height:100%;
										text-decoration:none;
									}
								}
								.ez_productinfo {
									min-height:140px;
									font-size: calc(0.75 * #{ezentrum_variables.$body-font-size});
									>span {
										display:block;
										margin-bottom: 0.5 * ezentrum_variables.$global-margin;
										width: 100%;
										text-align:unset;
										line-height: calc(0.5 * #{ezentrum_variables.$body-line-height});
										&:after {
											content:"";
											display:block;
											clear:both;
										}
									}
									.info_icon {
										margin-right:4px;
										float:left;
										width:0.85 * ezentrum_variables.$body-font-size;
										height:0.85 * ezentrum_variables.$body-font-size;
										background-color:transparent;
										background-size:contain;
										background-repeat:no-repeat;
										&.standard {
											border-radius: 50%;
											background-color:ezentrum_variables.$gray;
										}
									}
									.info_text {
										display:inline-block;
										width:calc(100% - 20px);
										vertical-align:top;
									}
									.ez_availability {
										position: relative;
										top: unset;
										right: unset;
										float:left;
										.ez_availabilitytext {
											padding-left:0.2 * ezentrum_variables.$global-padding;
											line-height:1rem;
											color: ezentrum_variables.$body-font-color;
										}
										.ez_availabilityicon {
											margin-top:2px;
											margin-right:2px;
											float:left;
											width:0.85 * ezentrum_variables.$body-font-size;
											height:0.85 * ezentrum_variables.$body-font-size;
										}
										&.avail_reservierbar .ez_availabilityicon {
											background-color:#ee8b30;
										}
										&.avail_subskription .ez_availabilityicon {
											background-color:#294b64;
										}
									}
									.lieferkommentar {
										padding-left:1.3 * ezentrum_variables.$global-padding;
										display:block;
										clear:both;
										a {
											text-decoration:none;
										}
									}
									&:after {
										content:"";
										display:block;
										clear:both;
									}
								}
								.ez_productprice {
									padding-top: ezentrum_variables.$global-padding;
									height:35px;
									line-height: 0.94rem;
									text-align:left;
									span:not(:first-child) {
										font-size: calc(0.75 * #{ezentrum_variables.$body-font-size});
									}
									.ez_price_container {
										font-size: calc(1.4 * #{ezentrum_variables.$body-font-size});
										font-weight:700;
										letter-spacing: 0px;
									}
									.ez_normalpreis, .ez_sonderpreis {
										font-size: 1.2 * ezentrum_variables.$body-font-size;
										font-weight: 700;
									}
									.ez_normalpreis {
									}
									.ez_sonderpreis {
										color: ezentrum_custom_variables.$red;
									}
									.ez_grundpreis {
										font-weight:700;
										color: ezentrum_variables.$body-font-color;
									}
									.ez_availability {
										position: relative;
										margin: 0;
										top: 0;
										right:0;
									}
								}
							}
						}
						.ez_product_bottom {
							@include ezentrum_mixins.display-flex;
							@include ezentrum_mixins.flex-justify-content(flex-end);
							@include ezentrum_mixins.flex-flow(column,nowrap);
							padding: 0.5 * ezentrum_variables.$global-padding;
							width:100%;
							min-height:66px;
							font-size:calc(0.6 * #{ezentrum_variables.$body-font-size});
							line-height:0.8rem;
							text-align:center;
							.ez_productnumber {
								letter-spacing:0.2px;
								a {
									text-decoration:none;
								}
							}
							.to_cart {
								margin: -0.5 * ezentrum_variables.$global-margin;
								margin-top: 0.5 * ezentrum_variables.$global-margin;
								margin-bottom: -1.2rem;
							}
						}
						&.dummy {
							height:1px;
							&:hover {
								background:transparent;
							}
						}
					}
				}
				.ez_productlist_headline {
					h1 {
						margin-top:0.5 * ezentrum_variables.$global-margin;
						font-size:1rem;
						font-weight:400;
						text-transform: uppercase;
					}
				}
				.ez_productlist_description {}
				vertriebspartner_list {
					#ez_main_filter_button {
						background-color:ezentrum_custom_variables.$light-yellow;
						&:hover {
							background-color:ezentrum_variables.$body-font-color;
							.ez_icon {
								fill:ezentrum_variables.$primary-color;
							}
						}
					}
				}
			}
		}
	}
}
/*
.listing\.htm {
	#search_query_string, #tab-shop, #next_button_container { display: none; }
}
*/
