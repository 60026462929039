﻿@use "../scss_modules/ezentrum_variables";

@mixin for-size($size) {
  @if $size == phone-only {
    @media (max-width: ezentrum_variables.$smartphone-max-size) { @content; }
  } @else if $size == tablet-portrait-up {
    @media (min-width: ezentrum_variables.$tablet-portrait-min-size) { @content; }
  } @else if $size == tablet-landscape-up {
    @media (min-width: ezentrum_variables.$tablet-landscape-min-size) { @content; }
  } @else if $size == desktop-up {
    @media (min-width: ezentrum_variables.$desktop-min-size) { @content; }
  } @else if $size == large-desktop-up {
    @media (min-width: ezentrum_variables.$big-desktop-min-size) { @content; }
  }
}

@mixin display-flex() {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}
@mixin flex-flow($dir,$wrap) {
	$dir: row !default;
	-webkit-flex-direction: $dir;
	-ms-flex-direction: $dir;
	flex-direction: $dir;
	-webkit-flex-wrap: $wrap;
	-ms-flex-wrap: $wrap;
	flex-wrap:$wrap;
}
@mixin flex-align-content($align) {
	$align: flex-start !default;
	-webkit-align-content: $align;
	@if $align == stretch {
		-ms-flex-line-pack: stretch;
	} @else if $align == center {
		-ms-flex-line-pack: center;
	} @else if $align == flex-start {
		-ms-flex-line-pack: start;
	} @else if $align == flex-end {
		-ms-flex-line-pack: end;
	} @else if $align == space-around {
		-ms-flex-line-pack: distribute;
	} @else if $align == space-between {
		-ms-flex-line-pack: justify;
	}
	align-content: $align;
}
@mixin flex-align-items($align) {
	$align: flex-start !default;
	-webkit-align-items: $align;
	@if $align == stretch {
		-ms-flex-align: stretch;
	} @else if $align == baseline {
		-ms-flex-align: baseline;
	} @else if $align == center {
		-ms-flex-align: center;
	} @else if $align == flex-start {
		-ms-flex-align: start;
	} @else if $align == flex-end {
		-ms-flex-align: end;
	}
	align-items: $align;
}
@mixin flex-align-self($align) {
	$align: flex-start !default;
	-webkit-align-self: $align;
	@if $align == auto {
		-ms-flex-item-align: auto;
	} @else if $align == baseline {
		-ms-flex-item-align: baseline;
	} @else if $align == center {
		-ms-flex-item-align: center;
	} @else if $align == flex-start {
		-ms-flex-item-align: start;
	} @else if $align == flex-end {
		-ms-flex-item-align: end;
	} @else if $align == stretch {
		-ms-flex-item-align: stretch;
	}
	align-self: $align;
}
@mixin flex-justify-content($just) {
	$just: space-between !default;
	-webkit-justify-content: $just;
	@if $just == flex-start {
		-ms-flex-pack: start;
	} @else if $just == flex-end {
		-ms-flex-pack: end;
	} @else if $just == center {
		-ms-flex-pack: center;
	} @else if $just == space-between {
		-ms-flex-pack: justify;
	} @else if $just == space-around {
		-ms-flex-pack: distribute;
	} @else if $align == space-evenly {
		-ms-flex-pack: justify;
	}
	justify-content: $just;
}

@mixin small-text() {
	font-size: 0.7 * ezentrum_variables.$body-font-size;
	line-height: 0.7 * ezentrum_variables.$body-line-height;
}

@mixin full-width() {
	margin:0;
	padding:0;
	width:100%;
}

@mixin break-word() {
	word-wrap: break-word;
	-moz-hyphens: auto;
	-o-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}

/* alte mixins -- NICHT MEHR VERWENDEN -- */
/*
@mixin flex-direction ($direction){
	-webkit-flex-direction: $direction;
	flex-direction: $direction; 
}
@mixin flex-inline (){
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
}
@mixin flex-wrap($wrap) {
	$wrap: wrap !default;

	-webkit-flex-wrap: $wrap;
	-ms-flex-wrap: $wrap;
	flex-wrap: $wrap;
}
@mixin flex-grow ( $grow ){
	-webkit-box-flex: $grow;
	-webkit-flex-grow: $grow;
	-moz-flex-grow: $grow;
	-ms-flex-positive: $grow;
	flex-grow: $grow;
}
@mixin flex-basis ( $basis ){
	-webkit-flex-basis: $basis;
	-moz-flex-basis: $basis;
	-ms-flex-preferred-size: $basis;
	flex-basis: $basis;
}
@mixin set-flex($grow, $shrink, $basis) {
	$grow: 0 !default;
	$shrink: 0 !default;
	$basis: auto !default;

	-webkit-flex: $grow $shrink $basis;
	-ms-flex: $grow $shrink $basis;
	flex: $grow $shrink $basis;
}
@mixin flex-align-self($align) {
	$align: flex-start !default;

	-webkit-align-self: $align;
	-ms-align-self: $align;
	align-self: $align;
}

@mixin flex-order ( $order ){
	-webkit-box-ordinal-group: $order;  
  -moz-box-ordinal-group: $order;     
  -ms-flex-order: $order;     
  -webkit-order: $order;  
  order: $order;
}

@mixin flex-all-centered (){
	@include flex-justify( center );
	@include flex-align( center );
}
*/



/* ### start: placeholder selector ### */
/*
@mixin row($test) {
	content: map-get($test, "just");
	//@include justify-center();
	//@include flex-wrap();
}

@mixin transition ( $value... ){
	transition: $value;
	-webkit-transition: $value;
	-moz-transition: $value;
	-o-transition: $value;
}

@mixin transform ( $value... ){
	transform: $value;
	-webkit-transform: $value;
	-moz-transform: $value;
	-ms-transform: $value;
	-o-transform: $value;
}

@mixin box-shadow ( $value... ){
	box-shadow: $value;
	-webkit-box-shadow: $value;
	-moz-box-shadow: $value;
}

@mixin play-animation ( $name, $duration, $inifinity ) {
	-webkit-animation-name: $name;
	-webkit-animation-duration: $duration;
	
	-moz-animation-name: $name;
	-moz-animation-duration: $duration;
	
	-ms-animation-name: $name;
	-ms-animation-duration: $duration;
	
	animation-name: $name;
	animation-duration: $duration;

	-webkit-animation-timing-function: linear;
	-moz-animation-timing-function: linear;
	-ms-animation-timing-function: linear;
	animation-timing-function: linear;

	@if $inifinity {
		-webkit-animation-iteration-count: infinite;
		-moz-animation-iteration-count: infinite;
		-ms-animation-iteration-count: infinite;
		animation-iteration-count: infinite;
	}
}

*/