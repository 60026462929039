﻿/* _ezentrum_fonts.scss for font styling */

/* roboto-300 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("/resources/fonts/roboto-v29-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/resources/fonts/roboto-v29-latin-300.eot?#iefix") format("embedded-opentype"), url("/resources/fonts/roboto-v29-latin-300.woff2") format("woff2"), url("/resources/fonts/roboto-v29-latin-300.woff") format("woff"), url("/resources/fonts/roboto-v29-latin-300.ttf") format("truetype"), url("/resources/fonts/roboto-v29-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/resources/fonts/roboto-v29-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/resources/fonts/roboto-v29-latin-regular.eot?#iefix") format("embedded-opentype"), url("/resources/fonts/roboto-v29-latin-regular.woff2") format("woff2"), url("/resources/fonts/roboto-v29-latin-regular.woff") format("woff"), url("/resources/fonts/roboto-v29-latin-regular.ttf") format("truetype"), url("/resources/fonts/roboto-v29-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/resources/fonts/roboto-v29-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/resources/fonts/roboto-v29-latin-700.eot?#iefix") format("embedded-opentype"), url("/resources/fonts/roboto-v29-latin-700.woff2") format("woff2"), url("/resources/fonts/roboto-v29-latin-700.woff") format("woff"), url("/resources/fonts/roboto-v29-latin-700.ttf") format("truetype"), url("/resources/fonts/roboto-v29-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* mr-de-haviland-regular - latin */
@font-face {
  font-family: "Mr De Haviland";
  font-style: normal;
  font-weight: 400;
  src: url("/resources/fonts/mr-de-haviland-v12-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("/resources/fonts/mr-de-haviland-v12-latin-regular.eot?#iefix") format("embedded-opentype"), url("/resources/fonts/mr-de-haviland-v12-latin-regular.woff2") format("woff2"), url("/resources/fonts/mr-de-haviland-v12-latin-regular.woff") format("woff"), url("/resources/fonts/mr-de-haviland-v12-latin-regular.ttf") format("truetype"), url("/resources/fonts/mr-de-haviland-v12-latin-regular.svg#MrDeHaviland") format("svg");
  /* Legacy iOS */
}
