﻿@use "../ezentrum_variables";

[data-ez-module-formvalidator] .ez_input {
	position:relative;
	input[type="text"],
	input[type="number"],
	input[type="email"],
	input[type="password"],
	input[type="tel"] {
		color:ezentrum_variables.$body-font-color;
		&:read-only {
			color: ezentrum_variables.$gray;
			cursor:default;
		}
	}
	.ez_status {
		position:absolute;
	}
	.feedback {
		position:absolute;
		top:-1.4rem;
		font-size:calc(0.85 * #{ezentrum_variables.$body-font-size});
	}
}

[data-ez-module-formvalidator] .form_item {
	input {
		outline:0;
		box-shadow:none;
	}
	.false,
	.true {
		display:none;
	}
}
[data-ez-module-formvalidator] .form_item[data-ez-valid="false"] {
	.ez_icon.false {
		display:block;
		border-radius:50%;
		color:ezentrum_variables.$alert-color;
		background-color: ezentrum_variables.$white;
	}
	.ez_icon.true {
		display:none;
	}
	label.ez_checkboxlabel,
	span.ez_checkboxlabel {
		position:relative;
		.ez_icon.unchecked {
			color:ezentrum_variables.$alert-color;
		}
		.ez_icon.checked {
			color:ezentrum_variables.$success-color;
		}
		input[type="checkbox"] {
			display:none;
		}
	}
}

[data-ez-module-formvalidator] .form_item[data-ez-valid="true"] {
	.ez_icon.false {
		display:none;
	}
	.ez_icon.true {
		display:block;
		border-radius:50%;
		color:ezentrum_variables.$success-color;
		background-color: ezentrum_variables.$white;
	}
	label.ez_checkboxlabel,
	span.ez_checkboxlabel {
		position:relative;
		.ez_icon.checked {
			color:ezentrum_variables.$success-color;
		}
		input[type="checkbox"] {
			display:none;
		}
	}
}

[data-ez-module-formvalidator] .form_item[data-ez-valid] .feedback.invalid {
	color:ezentrum_variables.$alert-color;
}

[data-ez-module-formvalidator] .form_item[data-ez-valid="false"] .icon.valid-status::before {
  content: "";
}
[data-ez-module-formvalidator] .form_item[data-ez-valid="true"] .icon.valid-status::before {
  content: "";
}
