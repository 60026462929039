﻿@use "../ezentrum_variables";

[data-ez-module-passwordchecker][data-ez-repeated-chars="true"] .helper .helper-item.repeated-chars,
[data-ez-module-passwordchecker][data-ez-min-length="true"] .helper .helper-item.min-length,
[data-ez-module-passwordchecker][data-ez-max-length="true"] .helper .helper-item.max-length {
	color:ezentrum_variables.$success-color;
	.ez_icon.false {
		display:none;
	}
	.ez_icon.true {
		display:inline;
		float:right;
		margin-right:ezentrum_variables.$global-margin;
		color:ezentrum_variables.$success-color;
	}
}

[data-ez-module-passwordchecker][data-ez-repeated-chars="false"] .helper .helper-item.repeated-chars,
[data-ez-module-passwordchecker][data-ez-min-length="false"] .helper .helper-item.min-length,
[data-ez-module-passwordchecker][data-ez-max-length="false"] .helper .helper-item.max-length {
 	color:ezentrum_variables.$alert-color;
	.ez_icon.false {
		display:inline;
		float:right;
		margin-right:ezentrum_variables.$global-margin;
		color:ezentrum_variables.$alert-color;
	}
	.ez_icon.true {
		display:none;
	}
}


[data-ez-module-passwordchecker] {
	.output_text {
		display:none;
	}
	.output_bar {
		margin-bottom: 0.5 * ezentrum_variables.$global-margin;
		height:3px !important;
		border:none !important;
		background-color: lighten(ezentrum_variables.$gray,20);
	}
	input[type="password"] {
		margin-bottom:0;
	}
}
