﻿@use "../ezentrum_variables";
@use "../../scss_libraries/ezentrum_mixins";

.captcha_container {
	@include ezentrum_mixins.display-flex;
	wrap: wrap;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	@include ezentrum_mixins.flex-justify-content(center);
	border-radius: ezentrum_variables.$global-radius;
	background-color: ezentrum_variables.$gray;
	border: 1px solid ezentrum_variables.$gray;
	.captcha_input {
		border-radius: ezentrum_variables.$global-radius;
		background-color: ezentrum_variables.$white;
	}
	.captcha_image_container {
		width: 100%;
		text-align: center;
	}
}