/* _ezentrum_customize_checkout.scss for custom styling on all checkout pages */

@use "ezentrum_fonts";
@use "ezentrum_variables";
@use "ezentrum_custom_variables";
@use "../scss_libraries/ezentrum_mixins";


.ez_warenkorbpreview {
	.ez_warenkorbpreview_button {
	}
}

#ez_wrapper {
	#ez_content_fullwidth {
		#ez_content_wrapper {
			#ez_content {
				.ez_checkout {
					background-color: transparent;
					background: transparent;
					.ez_product {
						.lieferkommentar {
							display:block;
							font-weight:400;
							color:ezentrum_custom_variables.$red;
						}
					}
					.standard_za {
						margin-bottom:2 * ezentrum_variables.$global-margin;
					}
					#sepa {
						display:none;
						position:relative;
						margin:0;
						padding:ezentrum_variables.$global-padding;
						width:100%;
						background:white;
						border:1px solid ezentrum_variables.$gray;
						border-radius: ezentrum_variables.$global-radius;
						/* box-shadow: 1px 1px 5px lightgrey;*/
						z-index:100;
					}
					input[type="radio"]#za_12:checked ~ #sepa {
						display:block;
					}
					.nav-checkout {
						background-color: ezentrum_variables.$body-font-color;
						color: ezentrum_variables.$primary-color;
						padding: 1.5%;
						font-weight: 500;
						text-transform: uppercase;
						font-size: 1rem;
						text-align: center;
						display: flex;
						flex-direction: row;
						.nav-checkout-item {
							display: block;
							width: 100%;
							@include ezentrum_mixins.for-size(tablet-landcape-up) {
								width: 25%;
							}
							&.nav-item {
								opacity: 50%;
							}
						}
						.nav-item, .nav-item-step-2, .nav-checkout-step-3, .nav-item-step-4 {
							opacity: 50%;
						}
					}
					@media only screen and (min-width: 880px) {
						.pinard-warenkorb-nav {
							margin-bottom: ezentrum_variables.$global-margin;
						}
						.nav-checkout {
							display: flex;
							align-items: center;
							font-size: 1rem;
						}
					}
					@media only screen and (max-width: 880px) {
						.nav-item, .nav-checkout, .nav-checkout-item, .nav-item-step-2 {
							display: none;
							display: flex;
							font-size: 1rem;
						}
						#first-nav-item-step-2 {
							display: none;
							font-size: 1rem;
						}
						.nav-item,
						.nav-checkout-step-3,
						.nav-item-step-4 {
							display: none !important;
						}
						.nav-item-step-4,
						.nav-item {
							flex-direction: column;
							align-items: center;
							display: none;
						}
					}
					@media only screen and (max-width: 767px) {
						.ez_price_padding {
							padding-right: 2 * ezentrum_variables.$global-padding;
						}
					}
					a.articlelink {
						font-weight: 700;
						text-decoration:none;
						// color: ezentrum_variables.$body-font-color;
						.erzeuger {
							font-size: calc(0.85 * 0.95394rem);
							line-height: calc(0.85 * 1.6rem);
							font-weight: 400;
						}
					}
					.padding-top {
						padding-top: ezentrum_variables.$global-padding;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							padding-top: 2 * ezentrum_variables.$global-padding;
						}
					}
					.ez_basket_top_white_bg {
						background-color: ezentrum_variables.$white;
						.ez_sonderpreis, .ez_sum {
							font-weight: 700;
							font-size: 1rem;
						}
						.ez_productprice  {
							float:right;
							span:not(:first-child) {
								font-size: calc(0.75 * #{ezentrum_variables.$body-font-size});
							}
							.ez_price_container {
								letter-spacing: 0px;
							}
							.ez_normalpreis, .ez_sonderpreis {
								font-size: 1.2 * ezentrum_variables.$body-font-size;
								font-weight: 700;
							}
							.ez_normalpreis {
							}
							.ez_sonderpreis {
								color: ezentrum_custom_variables.$red;
							}
							.ez_grundpreis {
								font-weight:700;
								color: ezentrum_variables.$body-font-color;
							}
						}
						#ez_basket_delivery_value {
							text-align: end;
						}
						#ez_delete {
							color:ezentrum_variables.$primary-color;
						}
						.ez_productimage {
							img {
								max-height:120px;
								float: left;
								margin: 5%;
								height: 10em;
							}
						}
						span.kreditkarte {
							img {
								height:16px;
							}
						}
					}
					.text-lastcolumn {
						text-align:right;
						padding-right: 2 * ezentrum_variables.$global-padding;
					}
					#form_kasse, .ez_basket_step_2_payment, .ez_basket_step_3, .ez_basket_step_4 {
						margin-bottom: auto;
					}
					.icon_aktualisieren {
						display:inline-block;
						width:14px;
						height:14px;
						background-image:url(/resources/images/pinard/icon-aktualisieren.svg);
						background-size:contain;
						background-repeat:no-repeat;
					}
					/* BASKET – Table row */
					.ez_basket_table_row_img {
						width: 3.375rem;
						height: 9.375rem;
					}
					.ez_basket_reiter {
						margin-top: 0;
						padding-top: 1.25rem;
					}
					.text-left {
						text-align: left;
					}
					.text-right {
						text-align: right;
						font-weight: 700;
					}
					.mobile-bezeichner {
						float:left;
						padding-left: ezentrum_variables.$global-padding;
					}
					.ez_productimage {
						display: flex;
						justify-content: center;
						align-items: center;
						height: 70%;
					}
					@media only screen and (max-width: 767px) {
						.ez_basket_step1 {
							padding: 0.625rem 0;
						}
					}
					#ez_basket_artikel {
						margin: 0 ezentrum_variables.$global-margin;
						padding: 0 ezentrum_variables.$global-padding;
						font-weight: 700;
						font-size: 1.2rem;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							padding: 0;
						}
						@include ezentrum_mixins.for-size(tablet-landscape-up) { 
							padding: 0;
						}												
						@include ezentrum_mixins.for-size(desktop-up) {
							padding: 0;
						}
					}
					@media only screen and (max-width: 767px) {
						#ez_basket_first_hr {
							margin-top: 0;
						}
					}
					.ez_basket_article_description {
						padding-left: 2 * ezentrum_variables.$global-padding;
					}
					.ez_basket_small_text,
					.ez_availabilitytext,
					.ez_sku {
						font-size: 0.85 * ezentrum_variables.$body-font-size;
						line-height: 0.85 * ezentrum_variables.$body-line-height;
						margin-top: 6%;
					}

					.ez_basket_delete_text {
						font-size: 0.85 * ezentrum_variables.$body-font-size;
					}
					.ez_basket_delivery_details {
						padding-left: 2 * ezentrum_variables.$global-padding;
					}
					b, strong {
						font-weight: 700;
					}
					.ez_basket_tab {
						font-weight: 600;
						padding: 0 ezentrum_variables.$global-padding;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							&:nth-child(2) {
								padding-left: 2 * ezentrum_variables.$global-padding;
							}
							&:last-child {
								padding-right: 2 * ezentrum_variables.$global-padding;
							}
						}
					}
					.ez_basket_gesamtsumme {
						margin-bottom: 4.375rem;
						font-weight: 700;
						font-size: 1rem;
					}
					.ez_warenkorb_button_aktualsieren {
						background-color: ezentrum_variables.$white;
						border: 1px solid ezentrum_variables.$secondary-color;
						border-top: 0;
					}
					.ez_warenkorb_button_aktualsieren:hover {
						color: ezentrum_custom_variables.$red;
					}
					.ez_grundpreis {
						&~.ez_sonderpreis {
							color: ezentrum_custom_variables.$red;
						}
					}
					.ez_basket_button_right, .ez_basket_button_left {
						padding: 0;
						@include ezentrum_mixins.for-size(tablet-portrait-up) {
							padding: 0 ezentrum_variables.$global-padding;
						}
					}
					#lieferanschrift_bottom {
						margin-top: ezentrum_variables.$global-margin;
						margin-bottom: ezentrum_variables.$global-margin;
						padding-top: ezentrum_variables.$global-padding;
						padding-bottom: ezentrum_variables.$global-padding;
					}
					.kasse {
						.ez_sonderpreis {
							font-weight:300;
						}
						.artikelbezeichnung {
							font-weight: 700;
							.erzeuger {
								font-size: calc(0.85 * 0.95394rem);
								line-height: calc(0.85 * 1.6rem);
								font-weight: 400;
							}
						}
					}
					.frachtkosten-table {
						table {
							width:100%;
							tr:nth-child(odd) {
								background-color:#eeeeee;
							}
							th {
								background-color:ezentrum_variables.$gray;
							}
						}
					}
					#agb_accept {
						border: 1px solid ezentrum_variables.$secondary-color;
						label {
							margin: .8rem 0;
						}
					}
					.basket_checkoutbuttons {
						margin-top: ezentrum_variables.$global-margin;
					}
				}
			}
		}
	}
}