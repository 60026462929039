﻿@use "../ezentrum_variables";
@use "../../scss_libraries/ezentrum_mixins";

[data-ez-module-expandbox] {
	position:relative;
	padding-bottom:ezentrum_variables.$global-padding;
	overflow-y:hidden;
}
[data-ez-module-expandbox] [data-ez-output] {
	background-color: transparent !important;
}
.expand-area {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	color: ezentrum_variables.$primary-color;
}
.expand-area .expand-gradient {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 120px;
	background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}
.expand-area .expand-gradient .expand-button {
	position: absolute;
	right: 0;
	bottom: 0;
}
.expand-area:hover {
	color: ezentrum_variables.$primary-color-hover;
}
.shrink-area {
	width: 100%;
	height: 2 * ezentrum_variables.$global-margin;
	color: ezentrum_variables.$primary-color;
}
.shrink-area .shrink-button {
	position: absolute;
	right: 0;
	bottom: 0;
}
.shrink-area:hover {
	color: ezentrum_variables.$primary-color-hover;
}
.last.block {
	margin-bottom:2 * ezentrum_variables.$global-margin;
}
